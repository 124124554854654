import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation, SuspenseQuery, Suspense } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { schemaEdit, uiSchemaEdit } from "../models/schemas";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  getDataSourceLabels,
  updateAlertDefinition,
  getAlertDefinitionById,
  formatAlertDefinition
} from "../services";
import {
  AlertDefinitionDTO,
  CreateAlertDefinitionCommand,
  EditAlertDefinitionCommand,
  SchemaErrors
} from "../models";
import { alertConditionValidator } from "../helpers";
import { HTTPResponse } from "app/network/dataProvider/model";

type RouteParams = {
  id: string;
};

interface AlertDefinitionEditProps extends RouteComponentProps<RouteParams> {
  updateAlertDefinition: (
    data: EditAlertDefinitionCommand
  ) => Promise<AlertDefinitionDTO>;
  getAlertDefinitionById: (id: string) => Promise<AlertDefinitionDTO>;
  satellites: any;
  datasources: any;
}

interface AlertDefinitionEditState {
  formData: EditAlertDefinitionCommand | null;
}

export class AlertDefinitionEdit extends Component<
  AlertDefinitionEditProps,
  AlertDefinitionEditState
> {
  state = {
    formData: null,
    datasource: {}
  };

  onChange(form: AlertDefinitionEditState) {
    this.setState({ formData: form.formData });
  }

  query = () => {
    const { id } = this.props.match.params;
    return getAlertDefinitionById(id);
  };

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: EditAlertDefinitionCommand
  ) {
    e.preventDefault();
    const { id: alertDefinitionId } = this.props.match.params;
    const { status } = (await updateAlertDefinition(
      alertDefinitionId,
      formData as any
    )) as HTTPResponse;
    if (status === 200) {
      this.props.history.push("/alert-definitions");
    }
  }

  render() {
    const { formData } = this.state;
    const { datasources } = this.props;

    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box
                  color="text.default"
                  data-testid="alert-definition-edit"
                  mx={3}
                >
                  <Flex mb={2}>
                    <Heading display={1}>Edit Alert Definition</Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => {
                        return (
                          <Form
                            validate={(
                              formData: CreateAlertDefinitionCommand,
                              errors: SchemaErrors
                            ) =>
                              alertConditionValidator(
                                formData,
                                errors,
                                datasources
                              )
                            }
                            formData={
                              formData ||
                              formatAlertDefinition(response.data, datasources)
                            }
                            schema={schemaEdit()}
                            uiSchema={uiSchemaEdit}
                            disabled={loading}
                            onSubmit={(
                              form: { formData: EditAlertDefinitionCommand },
                              e: React.FormEvent<HTMLInputElement>
                            ) => action(() => this.submit(e, form.formData))}
                            onChange={(form: AlertDefinitionEditState) =>
                              this.onChange(form)
                            }
                          >
                            <CancelButton
                              onClick={() =>
                                this.props.history.push("/alert-definitions")
                              }
                              mr={2}
                            >
                              Cancel
                            </CancelButton>
                            <SaveButton type="submit" disabled={loading}>
                              Save
                            </SaveButton>

                            {error ? (
                              <Box my={2}>
                                <Text color="text.danger">Error...</Text>
                              </Box>
                            ) : null}
                          </Form>
                        );
                      }}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => ({
  satellites: state.constellations.dashboard.map((sat: any) => ({
    id: sat.id,
    name: sat.label
  })),
  datasources: getDataSourceLabels(
    state.satellite.dashboard.satelliteDefinition
  )
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => ({});

export const AlertDefinitionEditContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertDefinitionEdit)
);
