import React from "react";
import { Switch, Route } from "react-router-dom";
import { PassageBooker } from "./components/Booker";
import { ConfigCreateContainer } from "./components/Configuration/ConfigCreateContainer";
import { Configs } from "./components/Configuration";

export const PassageBookerRoutes = () => (
  <>
    <Switch>
      <Route path="/passage-booker" exact component={Configs} />
      <Route
        path="/passage-booker/create"
        exact
        component={ConfigCreateContainer}
      />
      <Route path="/passage-booker-configs" exact component={Configs} />
      <Route
        path="/passage-booker-candidates"
        exact
        component={PassageBooker}
      />
    </Switch>
  </>
);
