export const FETCH_PASSAGE_START = "FETCH_PASSAGE_START";
export const FETCH_PASSAGE_SUCCESS = "FETCH_PASSAGE_SUCCESS";
export const FETCH_PASSAGE_ERROR = "FETCH_PASSAGE_ERROR";
export const FETCH_BOOKER_CONFIGS_SUCCESS = "FETCH_BOOKER_CONFIGS_SUCCESS";
export const CREATE_BOOKER_CONFIG_SUCCESS = "CREATE_BOOKER_CONFIG_SUCCESS";
export const CREATE_BOOK_REQUEST_SUCCESS = "CREATE_BOOK_REQUEST_SUCCESS";
export const CREATE_BOOK_REQUEST_ERROR = "CREATE_BOOK_REQUEST_ERROR";
export const UPDATE_BOOKER_CONFIG_SUCCESS = "UPDATE_BOOKER_CONFIG_SUCCESS";
export const DELETE_BOOKER_CONFIG_SUCCESS = "DELETE_BOOKER_CONFIG_SUCCESS";
export const FETCH_PASSAGE_CANDIDATES_SUCCESS =
  "FETCH_PASSAGE_CANDIDATES_SUCCESS";
