export const transformFormData = (
  formData: any,
  satellites: any,
  groundStations: any
) => {
  const groundStationsBySatellite = formData?.groundStationsBySatellite.map(
    (sat: any) => {
      return {
        [findSatId(satellites, sat.satellite)]: sat.groundStations.map(
          (gs: any) => findGSId(groundStations, gs)
        )
      };
    }
  );

  return {
    name: formData.name,
    description: formData?.description,
    startTime: formData?.daterange.from,
    endTime: formData?.daterange.to,
    groundStationsBySatellite: Object.assign({}, ...groundStationsBySatellite),
    strategyId: 1
  };
};

export function createEditFormData(
  data: any,
  satellites: any[],
  groundStations?: any[]
) {
  const config: any = {};
  config.name = data.name;
  config.description = data.description;
  config.provider = data.strategyId;
  config.daterange = {
    from: new Date(data.startTime),
    to: new Date(data.endTime)
  };
  config.additionalData = {};

  const _satellites = Array.from(
    new Set(Object.keys(data.groundStationsBySatellite).map((sat: any) => sat))
  );

  config.groundStationsBySatellite = _satellites.map((satID) => {
    const selectedGroundStations = data?.groundStationsBySatellite[satID];
    return {
      satellite: satellites.find((sat: any) => sat.label === satID)?.label,
      groundStations: selectedGroundStations
    };
  });

  return config;
}

function findGSId(groundStations: any[], groundStationName: string) {
  return groundStations.find((gs) => gs.groundStationName === groundStationName)
    ?.groundStationID;
}

function findSatId(satellites: any[], satellite: string) {
  return satellites.find((sat) => sat.label === satellite).id;
}
