import React, { useEffect, useCallback } from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { CancelButton, EditButton, SaveButton } from "components";
import { connect } from "react-redux";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "primitives";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "app/shared";
import {
  bookerConfigSchemaEdit,
  bookerConfigUISchemEdit
} from "app/passageBooker/models/schema";
import { fetchGroundStationsAction as gs } from "app/groundStation/actions";
import { transformFormData, createEditFormData } from "app/passageBooker/utils";
import { updatePassageBookerConfigs } from "app/passageBooker/actions";

interface PassageBookerConfigEditProps {
  config?: any;
  deleteBookerConfig?: (id: string) => Promise<any>;
  closeModal?: any;
}

export const PassageBookerConfigEdit = ({
  config,
  ...props
}: PassageBookerConfigEditProps) => {
  const dispatch = useDispatch();
  const satellites = useSelector((s: any) => s.constellations.dashboard);
  useEffect(() => {
    dispatch(gs());
  }, []);

  const groundStations = useSelector(
    (s: any) => s.groundStations?.groundStations
  );

  const editFormData = createEditFormData(config, satellites, groundStations);
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    onToggle: any
  ) => {
    e.preventDefault();
    const formattedData = transformFormData(
      formData,
      satellites,
      groundStations
    );
    dispatch(
      updatePassageBookerConfigs(config.id, { id: config.id, ...formattedData })
    );
    onToggle();
  };

  const validator = (formData: any, errors: any) => {
    console.log("ADD custom validations", formData);
    return errors;
  };

  const transformData = (formData: any) => {
    if (formData.daterange?.from && formData.daterange?.to) {
      formData.daterange.from = new Date(
        formData?.daterange?.from
      ).toISOString();
      formData.daterange.to = new Date(formData?.daterange?.to).toISOString();
    }
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  const formData = {};

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <EditButton onClick={onToggle}>EDIT</EditButton>
          <Dialog
            open={toggled}
            maxWidth="lg"
            data-testid="passage-booker-edit"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>
                      Edit passage booking configuration with id: {config.id}?
                    </DialogTitle>
                    <DialogContent>
                      <Flex alignItems="center">
                        <Form
                          id="passage-booker"
                          validate={(formData: any, errors: any) =>
                            validator(formData, errors)
                          }
                          formData={editFormData}
                          schema={bookerConfigSchemaEdit(
                            satellites,
                            groundStations,
                            "config",
                            []
                          )}
                          uiSchema={bookerConfigUISchemEdit}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: any },
                            e: React.FormEvent<HTMLInputElement>
                          ) => {
                            return submit(e, form.formData, onToggle);
                          }}
                          onChange={(form: any) => onChange(form)}
                        >
                          <></>
                        </Form>
                      </Flex>
                    </DialogContent>
                    <DialogActions>
                      <SaveButton
                        form="passage-booker"
                        type="submit"
                        mr={1}
                        disabled={loading}
                      >
                        Save
                      </SaveButton>
                      <CancelButton onClick={onToggle} disabled={loading}>
                        Cancel
                      </CancelButton>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({});

export const ConfigEditContainer = connect(
  null,
  mapDispatchToProps
)(PassageBookerConfigEdit);
