import React from "react";
import { Header } from "../Header";
import { CandidatesTable } from "./CandidatesTable";

export const PassageBooker = (props: any) => {
  return (
    <>
      <Header {...props} />
      <CandidatesTable />
    </>
  );
};
