import {
  Oa,
  OperationActivityFilterState,
  CalendarTimelineItem,
  Passage,
  TimeRange,
  ITimeline,
  TimelineEntry,
  TimelineTypes,
  TimelineEntryType,
  ISODateString,
  CalendarTimeline,
  FetchTimelineResponse,
  TimelineWindow,
  OaHandleState,
  CreateOaParams,
  PatchOaParams,
  HandleType,
  OperationDialogActionName,
  SchemaErrors,
  FOPType,
  Overlaps,
  TimelineEntryStatus
} from "../models";
import { getDateAtUTCOffset } from "components/TimelineRenderer/utils/helpers";
import { fetchProcedure } from "app/procedure/services";
import { fetchScript, getScriptResourceUrl } from "app/scripting/services";
import { v4 as uuid } from "uuid";
import { passageTimelineGroup, calendartimelineGroups } from "./constants";
import palette from "styles/palette";
import { getSatelliteName } from "app/shared/utils";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";
import { isEqual } from "lodash";
import yaml from "js-yaml";

const OA_TYPES = ["NORMAL", "CONTINGENT"];
const OA_PRIORITY = [
  "OPS_TEAM_OVERRIDE",
  "CUST_ROUTINE_PLAN",
  "OPS_ROUTINE_PLAN"
];
const OA_STATUS = [
  "Draft",
  "Submitted",
  "Rejected",
  "Accepted",
  "Tasked",
  "In Execution",
  "Cancelled",
  "Success",
  "Failed"
];
const FOP_TYPES = ["GOP", "MOP"];

const getRandom = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)];

export const generateOaUuid = (quantity = 10): string[] => {
  const uuids: string[] = [];
  for (let index = 0; index < quantity; index++) {
    uuids.push(uuid());
  }
  return uuids;
};

export const generateMokedOa = (uuids: string[]): Oa[] => {
  return uuids.map((uuid, index) => ({
    name: `Operation Activity Instance -- ${index}`,
    description: "Mocked description",
    oaType: getRandom(OA_TYPES),
    priority: getRandom(OA_PRIORITY),
    satelliteID: "81",
    groundStationID: "mocked gs",
    missionEntities: ["mission", "entities"],
    taskStartTs: new Date().toISOString() as ISODateString,
    taskDuration: 600, // 10 min
    procedureExecutionStartTs: new Date().toISOString() as ISODateString,
    procedureExecutionDuration: 600,
    uuid,
    author: "Test Operator",
    lastModificationAuthor: "Test Operator",
    creationTs: new Date().toISOString() as ISODateString,
    lastModificationTs: new Date().toISOString() as ISODateString,
    status: getRandom(OA_STATUS),
    fop: {
      type: getRandom(FOP_TYPES),
      templateProcedureID: 1,
      resources: [1, 2, 3],
      parameters: {},
      logFileName: "logFileName",
      toExecuteProcedureID: 2,
      logFileID: 112,
      outputFile: "outputFile",
      telecommands: [
        {
          onBoardUID: "onboardUID",
          timeTag: new Date().toISOString() as ISODateString,
          boardID: 113
        }
      ]
    }
  }));
};

/**
 * @description TODO: TEST
 * @param filters
 * @param operations
 * @returns
 */
export const filterOperations = (
  filters: OperationActivityFilterState,
  operations: Oa[]
): Oa[] => {
  let filteredOperations: Oa[] = operations;
  for (const filterKey in filters) {
    if (
      Object.hasOwnProperty.call(filters, filterKey) &&
      typeof filters[filterKey as keyof OperationActivityFilterState] ===
        "string"
    ) {
      const _operations = filteredOperations.filter((op: Oa) => {
        const operationVal =
          (op && (op[filterKey as keyof Oa] as string).toLowerCase()) || "";
        const appliedFilter = (
          filters[filterKey as keyof OperationActivityFilterState] as string
        ).toLowerCase();
        return operationVal.includes(appliedFilter);
      });

      filteredOperations = [..._operations];
    }
  }

  return filteredOperations;
};

/**
 * @description remap Passage[] to CalendarTimelineItem[]
 * @param passages
 * @returns
 */
export const passageToTimeline = (
  passages: Passage[]
): CalendarTimelineItem[] => {
  const { satelliteInstances } = store.getState().constellations.selected || {};

  return passages.map((passage) => ({
    id: passage.passageID,
    group: passageTimelineGroup,
    start_time: getDateAtUTCOffset(passage.aos),
    end_time: getDateAtUTCOffset(passage.los),
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    title: `Satellite: ${getSatelliteName(
      passage.satelliteID,
      satelliteInstances
    )} - over - GS: ${passage.groundStationName}`,
    itemProps: {
      style: {
        background: palette.palette.purple[0]
      }
    }
  }));
};

/**
 * Given a list of CalendarTimelineItem it return the event much closer to the present UTC time
 * based on the start_time attribute.
 * @param eventList
 */
export const getNearestEvent = (
  eventList: CalendarTimelineItem[]
): CalendarTimelineItem | null => {
  const currentTime = getDateAtUTCOffset(new Date().toISOString());

  // Filter out events that are already in progress or have ended
  const futureEvents = eventList.filter(
    (event) => event.start_time > currentTime
  );

  if (futureEvents.length === 0) {
    return null; // No future events found
  }

  // Sort the future events by start_time in ascending order
  futureEvents.sort((a, b) => a.start_time - b.start_time);

  // Return the first event from the sorted array
  return futureEvents[0];
};

/**
 * @description This function takes as input a number indicating a range of hours
 * and return two dates that position the current time at the middle of the time range taken as input.
 * @param hours {number}
 * @returns { timeStart, timeEnd }
 */
export const getTimelineTimeWindow = (hours = 8): TimelineWindow => {
  const oneHour = 3600 * 1000;
  const now = new Date().valueOf();
  const timeOffset = (hours / 2) * oneHour;

  const startTime = new Date(now - timeOffset);
  const endTime = new Date(now + timeOffset);

  return { startTime, endTime };
};

export { getDateAtUTCOffset };

/**
 * Creates a list of time ranges with random durations between 10 minutes and 15 minutes,
 * within a time winddow defined by `begin` & `end` centered on the current date and time.
 * Used for mocking data.
 * @param {number} length - The number of time ranges to create
 * @param {Date} begin - Defines the limit to make begin a time range
 * @param {Date} end - Defines the limit to make end a time range
 * @returns {TimeRange[]} - An array of time range objects
 */
const createTimeRangeList = (
  length: number,
  begin: Date,
  end: Date
): TimeRange[] => {
  const timeRanges: TimeRange[] = [];
  const beginTime = begin.getTime();
  const endTime = end.getTime();

  if (beginTime >= endTime) {
    throw new Error("The 'begin' date must be earlier than the 'end' date");
  }

  const totalDuration = endTime - beginTime;
  const minDuration = 10 * 60 * 1000; // 10 minutes in milliseconds
  const maxDuration = 15 * 60 * 1000; // 15 minutes in milliseconds

  for (let i = 0; i < length; i++) {
    const randomDuration =
      Math.random() * (maxDuration - minDuration) + minDuration;
    const startTime =
      Math.random() * (totalDuration - randomDuration) + beginTime;
    const endTime = startTime + randomDuration;

    if (endTime <= end.getTime()) {
      timeRanges.push({
        start_time: startTime,
        end_time: endTime
      });
    } else {
      i--; // If the generated time range exceeds the 'end' date, retry this iteration
    }
  }

  return timeRanges;
};

/**
 * @description helper used to mock the response of the fetchTimeline service
 * @param numEntries
 * @returns {ITimeline[]}
 */
export const generateTimelines = (
  uuids: string[],
  begin: Date,
  end: Date
): ITimeline[] => {
  const timelineTypes = Object.values(TimelineTypes);
  const timelines: ITimeline[] = [];
  const timelineEntryType = Object.values(TimelineEntryType);
  const timelineEntryStatus = Object.values(TimelineEntryStatus);

  // Generate random data for each timeline entry
  const generateTimelineEntry = (
    timelineTypeIndex: number
  ): TimelineEntry[] => {
    const timeRanges = createTimeRangeList(uuids.length, begin, end);

    return uuids.map((id, i) => ({
      oaUUID: id,
      status: getRandom(timelineEntryStatus),
      executionStart: new Date(
        timeRanges[i].start_time
      ).toISOString() as ISODateString,
      executionEnd: new Date(
        timeRanges[i].end_time
      ).toISOString() as ISODateString,
      timelineEntryType:
        i <= uuids.length / 2 + 1
          ? TimelineEntryType.EXECUTE
          : getRandom(timelineEntryType), // execute.length must be >= than track.length. TRACK without execute cannot exists.
      timeRanges: [
        {
          startTs: new Date(
            timeRanges[i].start_time
          ).toISOString() as ISODateString,
          endTs: new Date(
            timeRanges[i].end_time
          ).toISOString() as ISODateString,
          priority: Math.floor(Math.random() * 10) + 1 // Generate priority between 1 and 10
        }
      ],
      bookedEntities: ["Entity A", "Entity B"],
      bookedSatellite: "Satellite X",
      bookedGroundStation: "Ground Station Y"
    }));
  };

  const getRandomTimelineType = (timelineTypeIndex: number): TimelineTypes => {
    return timelineTypes[timelineTypeIndex];
  };

  /**
   * The existing entries of type TRACK should have a correlated EXECUTE.
   * Assigning EXECUTE oaUUID to TRACK entry
   * Can exist only one TRACK correlated to an EXECUTE
   */
  const remapTrackEntryId = (ent: TimelineEntry[]): TimelineEntry[] => {
    return ent.reduce<TimelineEntry[]>((acc, curr) => {
      const associateToExecute = (
        timelineEntry: TimelineEntry
      ): TimelineEntry => {
        const execEntry = acc.find(
          (entr) =>
            entr.timelineEntryType === TimelineEntryType.EXECUTE &&
            acc.filter((itm) => itm.oaUUID === entr.oaUUID).length < 2
        );

        return (
          (execEntry && { ...timelineEntry, oaUUID: execEntry.oaUUID }) ||
          timelineEntry
        );
      };

      const entry =
        (curr.timelineEntryType === TimelineEntryType.EXECUTE && curr) ||
        associateToExecute(curr);

      return [...acc, entry];
    }, []);
  };

  // Generate the timelines
  for (let i = 0; i < timelineTypes.length; i++) {
    const timelineEntries = remapTrackEntryId(generateTimelineEntry(i));

    timelines.push({
      uuid: `${timelineTypes[i]}-${i}`,
      name: `${calendartimelineGroups[timelineTypes[i]].title}`,
      timelineType: getRandomTimelineType(i),
      entries: [...timelineEntries]
    });
  }

  return timelines;
};

/**
 * @description Map FetchTimelineResponse to CalendarTimeline
 * @param param0
 * @returns
 */
export const timelineToCalendarTimeline = ({
  timelines
}: FetchTimelineResponse): CalendarTimeline[] => {
  return timelines.map(({ uuid, name, timelineType, entries }) => ({
    uuid,
    name,
    timelineType,
    groups: [calendartimelineGroups[timelineType]],
    items: entries.map((entry) => ({
      id: `${entry.oaUUID}-${entry.timelineEntryType}`,
      canChangeGroup: false,
      group: calendartimelineGroups[timelineType].id,
      canMove:
        timelineType !== TimelineTypes.MASTER &&
        entry.timelineEntryType === TimelineEntryType.EXECUTE,
      canResize:
        timelineType !== TimelineTypes.MASTER &&
        entry.timelineEntryType === TimelineEntryType.EXECUTE,
      title: `BookedSatellite: ${entry.bookedSatellite} - BookedGroundStation: ${entry.bookedGroundStation}`,
      start_time: getDateAtUTCOffset(entry.executionStart),
      end_time: getDateAtUTCOffset(entry.executionEnd),
      className: ""
    }))
  }));
};

/**
 * @description It remap the OaHandleState (formData) to create the body od request for edit/create
 * basesd on the value of handleType (create|edit)
 * @param handleFormData
 * @param handleType
 * @returns CreateOaParams | PatchOaParams
 */
export const handleRequestBody = (
  handleFormData: OaHandleState,
  handleType: HandleType
): CreateOaParams | PatchOaParams | null => {
  try {
    const resources = handleFormData.fop.resources.map(
      ({ resource }) => resource
    );
    const parameters = yamlToJson(handleFormData.fop.parameters);
    const logFileName = handleFormData.fop.logFileName;

    const mopOptions =
      handleFormData.fopType === FOPType.MOP
        ? {
            satelliteID: handleFormData.satelliteID,
            groundStationID: handleFormData.groundStationID,
            missionEntities: handleFormData.missionEntities.map(
              ({ entity }) => entity
            ),
            procedureExecutionStartTs:
              handleFormData.procedureExecutionSetting.startTime,
            procedureExecutionDuration:
              handleFormData.procedureExecutionSetting.durationTime
          }
        : {};

    const oa = {
      name: handleFormData.name,
      description: handleFormData.description,
      oaType: handleFormData.oaType,
      priority: handleFormData.priority,
      taskStartTs: handleFormData.taskSetting.startTime,
      taskDuration: handleFormData.taskSetting.durationTime,
      ...mopOptions
    };

    const fop = {
      type: handleFormData.fopType,
      templateProcedureID: handleFormData.fop.templateProcedureID,
      resources,
      parameters,
      logFileName
    };

    const editProps = {
      fopResources: resources,
      fopParameters: parameters,
      fopLogFileName: logFileName
    };

    const createParams = { oa, fop } as CreateOaParams;
    const patchParams = { ...oa, ...editProps } as PatchOaParams;

    /**
     * Returning create/edit request parameters
     */
    return (
      (handleType === OperationDialogActionName.create && createParams) ||
      patchParams
    );
  } catch (error) {
    store.dispatch(
      setFeedback(
        "Error",
        FeedbackStatus.ERROR,
        "Check all the fields of the form"
      )
    );
    return null;
  }
};

/**
 * @description Used in the EDIT form. The received OA is remapped to OaHandleState
 * to be passed to the form as `formData`
 * @param operation
 * @returns OaHandleState
 */
export const operationToFormState = (
  operation: Oa,
  handleType: HandleType
): OaHandleState | null => {
  try {
    return {
      name:
        handleType === OperationDialogActionName.create
          ? undefined
          : operation.name,
      description: operation.description,
      oaType: operation.oaType,
      priority: operation.priority,
      fopType: operation.fop.type,
      satelliteID: operation.satelliteID,
      groundStationID: operation.groundStationID,
      missionEntities: operation.missionEntities.map((entity) => ({ entity })),
      procedureExecutionSetting: {
        startTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.procedureExecutionStartTs,
        durationTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.procedureExecutionDuration
      },
      taskSetting: {
        startTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.taskStartTs,
        durationTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.taskDuration
      },
      fop: {
        templateProcedureID: operation.fop.templateProcedureID,
        resources: operation.fop.resources.map((resource) => ({ resource })),
        parameters: jsonToYaml(operation.fop.parameters),
        logFileName: operation.fop.logFileName
      }
    };
  } catch (error) {
    store.dispatch(
      setFeedback(
        "Error",
        FeedbackStatus.ERROR,
        "Operation Activity corrupted. Unable to open it."
      )
    );
    return null;
  }
};

/**
 * @description Make a validation for the create/edit OA form.
 * It checks that the multivalue fields have no duplicated values.
 * The required fields are validated automaticly in the schema.
 * @param formData
 * @param errors
 * @returns SchemaErrors
 */
export const handleFormValidator = (
  formData: OaHandleState,
  errors: SchemaErrors
) => {
  /**
   * procedureExecutionStartTs > taskStartTs
   */
  if (
    formData.procedureExecutionSetting?.startTime &&
    formData.taskSetting?.startTime
  ) {
    const procedureExecutionStartTs = new Date(
      formData.procedureExecutionSetting.startTime
    );
    const taskStartTs = new Date(formData.taskSetting.startTime);
    if (procedureExecutionStartTs <= taskStartTs) {
      errors.procedureExecutionSetting.startTime.addError(
        "Procedure execution start time must be higher than task start time"
      );
    }
  }

  if (formData.procedureExecutionSetting?.startTime === "") {
    errors.procedureExecutionSetting.startTime.addError(
      "Procedure execution start time must be a valid date string"
    );
  }

  if (formData.taskSetting?.startTime === "") {
    errors.taskSetting.startTime.addError(
      "Start time must be a valid date string"
    );
  }

  /**
   * Parameters
   */
  if (formData.fop.parameters) {
    const json = yamlToJson(formData.fop.parameters);
    if (!json || typeof json !== "object") {
      errors.fop.parameters.addError("Invalid YAML");
    }
  }

  /**
   * Resources
   */
  if (formData.fop.resources && formData.fop.resources.length > 0) {
    formData.fop.resources.forEach((r, i, resources) => {
      const isDuplicated =
        resources.filter((resource) => isEqual(r, resource)).length > 1;
      if (isDuplicated) {
        errors.fop.resources[i].addError("Duplicated resource");
      }
    });
  }

  /**
   * Mission entities
   */
  if (formData.missionEntities && formData.missionEntities.length > 0) {
    formData.missionEntities.forEach((e, i, entities) => {
      const isDuplicated =
        entities.filter((entity) => isEqual(e, entity)).length > 1;
      if (isDuplicated) {
        errors.missionEntities[i].addError("Duplicated entity");
      }
    });
  }

  return errors;
};

const jsonToYaml = (json: object): string => {
  try {
    // Convert the JS object to a YAML
    return yaml.dump(json);
  } catch (e) {
    console.error("Error while converting from JSON to YAML:", e);
    return "";
  }
};

const yamlToJson = (yamlString: string): object | null => {
  try {
    // Convert a YAML into a js object
    return yaml.load(yamlString) as object;
  } catch (e) {
    console.error("Error while converting from YAML to JSON:", e);
    return null;
  }
};

const readResourceFile = async (downloadUrl: string): Promise<string> => {
  try {
    const response = await fetch(downloadUrl);

    return response.text();
  } catch (error: unknown) {
    // eslint-disable-next-line no-restricted-globals
    if (location.origin === "https://localhost:3000") {
      console.error(
        "Fetching AWS from https://localhost:3000 is disabled for security issues"
      );
    } else {
      console.error("An unknown error occurred");
    }
    return "An error occurred while getting the template";
  }
};

const getYamlTemplate = async (procedureId: number): Promise<string> => {
  try {
    const procedure = await fetchProcedure(procedureId);
    const firstStep = procedure?.steps.find((step) => step.order === 1);
    if (!firstStep) {
      store.dispatch(
        setFeedback(
          "Error",
          FeedbackStatus.ERROR,
          "Could not find a step with order 1 in the procedure"
        )
      );
      return "";
    }

    const script = await fetchScript(String(firstStep.scriptId));
    if (script.resources.length === 0) {
      store.dispatch(
        setFeedback(
          "Error",
          FeedbackStatus.ERROR,
          "Could not find any resource for this step"
        )
      );
      return "";
    }
    const resource = script.resources.find((r) =>
      r.s3ObjectName.includes(procedure.name)
    );

    if (!resource) {
      store.dispatch(
        setFeedback(
          "Error",
          FeedbackStatus.ERROR,
          `Could not find ${procedure.name}.yaml`
        )
      );
      return "";
    }

    const resourceUrl = await getScriptResourceUrl(resource.id);
    if (!resourceUrl) {
      store.dispatch(
        setFeedback("Error", FeedbackStatus.ERROR, `Resource url not valid`)
      );
      return "";
    }

    const resourceContent = await readResourceFile(resourceUrl.url);

    return resourceContent || `Template file is empty`;
  } catch (e) {
    // TODO: notify error while getting the yaml template
    return "";
  }
};

export const handleYamlTemplate = async (
  formData: OaHandleState
): Promise<OaHandleState> => {
  try {
    const yamlTemplate = await getYamlTemplate(
      formData.fop.templateProcedureID
    );

    if (typeof yamlTemplate === "string") {
      return {
        ...formData,
        fop: { ...formData.fop, parameters: yamlTemplate }
      };
    }

    return formData;
  } catch (error) {
    console.log(error);
    return formData;
  }
};

/**
 * Given a list of CalendarTimelineItem it checks if all the existing items
 * of type TRACK overlaps with its related EXECUTE item.
 * If yes it return both the overlapping items.
 */
export const executeOverlapsToTrack = (
  timelineItems: CalendarTimelineItem[]
): Overlaps => {
  const trackItems = timelineItems.filter((itm) =>
    String(itm.id).includes(TimelineEntryType.TRACK)
  );
  const executeItems = timelineItems.filter((itm) =>
    String(itm.id).includes(TimelineEntryType.EXECUTE)
  );

  // Find the first overlap between track and execute items
  for (const track of trackItems) {
    const trackRoot = String(track.id).split("-");
    trackRoot.pop(); // Remove the last part of the track ID

    const relatedExecute = executeItems.find((execute) =>
      String(execute.id).includes(trackRoot.join("-"))
    );

    if (relatedExecute && relatedExecute.end_time > track.start_time) {
      // Return the first pair that satisfies the condition
      return { track, relatedExecute };
    }
  }

  // If no overlaps are found, return null
  return null;
};
