import React, { useCallback, useRef, useEffect } from "react";
import { Flex } from "primitives";
import { useDispatch } from "react-redux";
import { AddButton, SaveButton, CancelButton } from "components";
import { Dialog, DialogTitle, DialogActions, Box } from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { BookerForm } from "./BookerForm";
import {
  getPassageCandidates,
  getPassageBookerConfigs
} from "app/passageBooker/actions";

export const BookerModal = (props: any) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const defaultToggled = false;
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  useEffect(() => {
    dispatch(getPassageBookerConfigs());
  }, [toggled]);

  const submit = useCallback(
    async (e: React.FormEvent<HTMLInputElement>, formData: any) => {
      e.preventDefault();
      dispatch(getPassageCandidates(formData?.configuration));
      props.history.push({
        pathname: "/passage-booker-candidates",
        state: { default: true }
      });
      onToggle();
    },
    []
  );

  const cancel = useCallback(() => {
    onToggle();
  }, []);

  return (
    <Flex ml={2}>
      <AddButton size={"small"} onClick={onToggle}>
        {"BOOKER"}
      </AddButton>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>
          Fetch Passages from Providers with a given configuration
        </DialogTitle>
        <Flex height="100%">
          <Box overflow="scroll" width="100%">
            <BookerForm
              {...props}
              formRef={formRef}
              closeModal={cancel}
              submit={submit}
              type="booker"
            />
          </Box>
        </Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton
              mr={1}
              type="submit"
              id="passage-booker"
              form="passage-booker"
            >
              Run
            </SaveButton>
            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </Flex>
  );
};
