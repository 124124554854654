import { SelectBox, InputBase } from "app/shared";
import { groundStations } from "app/shared/testUtils";
import { DateTimePicker } from "components";
import { Providers } from "..";
type Satellite = any;
type GroundStation = any;

export const bookerConfigSchemaCreate = (
  satellites: Satellite[] = [],
  groundStations: GroundStation[] = [],
  type: string,
  bookerConfigs: any[] = []
) => {
  const isBooker = type === "booker";
  const configSelector = (bookerConfigs?: any[]) => {
    if (bookerConfigs && !Array.isArray(bookerConfigs)) {
      return {};
    }
    return {
      configuration: {
        type: "number",
        title: "Select a passage booker configuration",
        enum: bookerConfigs?.map((p) => p.id),
        enumNames: bookerConfigs?.map((p) => p.name)
      }
    };
  };
  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "passages.schema.json",
    title: "Fetch Passages based on following configuration",
    name: "Fetch Passages",
    description:
      "The configuration options to book passages from various providers",
    type: "object",
    definitions: {
      time: {
        $id: "#/definitions/time",
        title: "Time",
        type: "string"
      }
    },
    properties: {
      ...(isBooker ? configSelector(bookerConfigs) : {}),
      name: {
        type: "string",
        title: "Name for the passage booker configuration",
        default: ""
      },
      description: {
        type: "string",
        title: "description",
        default: ""
      },
      // provider: {
      //   type: "number",
      //   title: "Select a groundstation provider",
      //   enum: [1, 2],
      //   enumNames: Object.values(Providers),
      //   default: 1
      // },
      daterange: {
        title: "Select a date range for fetching passages",
        type: "object",
        properties: {
          from: {
            type: "string",
            format: "date-time",
            source: "passage-booker"
          },
          to: {
            type: "string",
            format: "date-time",
            source: "passage-booker"
          }
        }
      },
      groundStationsBySatellite: {
        type: "array",
        hasRemove: true,
        title: "Select GroundStations for each satellite",
        items: {
          type: "object",
          properties: {
            satellite: {
              title: "Satellite",
              type: "string",
              enum: satellites?.map((satellite: Satellite) => satellite.label)
            },
            groundStations: {
              type: "array",
              title: "Ground Stations",
              items: {
                type: "string",
                enum: groundStations?.map(
                  (gs: GroundStation) => gs.groundStationName
                ),
                uniqueItems: true
              },
              uniqueItems: true
            }
          }
        }
      },
      additionalData: {
        title: "Add additional config options",
        type: "object",
        properties: {
          config: {
            type: "string",
            title: " "
          }
        }
      }
    }
  };
};

export const bookerConfigSchemaEdit = (
  satellites: Satellite[],
  groundStations: GroundStation[],
  type: string,
  bookerConfigs?: any[]
) => {
  return {
    type: "object",
    properties: {
      id: {
        type: "number",
        title: "Id",
        kind: "constant"
      },
      ...bookerConfigSchemaCreate(
        satellites,
        groundStations,
        type,
        bookerConfigs
      ).properties
    }
  };
};

export const bookerConfigDefault = {};

export const bookerConfigUISchema = {
  configuration: { "ui:widget": SelectBox },
  name: { "ui:widget": InputBase },
  description: { "ui:widget": InputBase },
  daterange: {
    classNames: "daterange-passage-booker",
    from: {
      "ui:widget": DateTimePicker,
      classNames: "daterange-passage-booker-from"
    },
    to: {
      "ui:widget": DateTimePicker,
      classNames: "daterange-passage-booker-to"
    }
  },
  groundStationsBySatellite: {
    items: {
      satellite: { classNames: "multi-select-sats", "ui:widget": SelectBox },
      groundStations: {
        "ui:widget": "checkboxes",
        classNames: "multi-select-gs"
      }
    }
  },
  additionalData: {
    config: {
      "ui:widget": InputBase
    }
  }
};

export const bookerConfigUISchemEdit = {
  id: { "ui:widget": "hidden" },
  ...bookerConfigUISchema
};
