import { Dispatch } from "react";
import {
  CREATE_BOOKER_CONFIG_SUCCESS,
  CREATE_BOOK_REQUEST_SUCCESS,
  DELETE_BOOKER_CONFIG_SUCCESS,
  FETCH_BOOKER_CONFIGS_SUCCESS,
  FETCH_PASSAGE_CANDIDATES_SUCCESS,
  FETCH_PASSAGE_SUCCESS,
  UPDATE_BOOKER_CONFIG_SUCCESS
} from "../models/actions";
import {
  getBookerConfigs,
  getPassagesListForBooking,
  updateBookerConfigs,
  createBookerConfigs,
  deleteBookerConfig,
  getPassageCandidatesForConfig,
  bookingPassage
} from "../services";

export const getPassagesForBooking = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const payload = await getPassagesListForBooking(data);
      dispatch({
        type: FETCH_PASSAGE_SUCCESS,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const getPassageCandidates = (id: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const { data, ...rest } = await getPassageCandidatesForConfig(id);
      dispatch({
        type: FETCH_PASSAGE_CANDIDATES_SUCCESS,
        payload: { data, pagination: rest },
      });
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const getPassageBookerConfigs = (pagination: any = {}) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const satellites = getSatsFromStore(getState);
      const groundStations = getGroundStationsFromStore(getState);
      const { data, ...rest } = await getBookerConfigs(pagination);
      dispatch({
        type: FETCH_BOOKER_CONFIGS_SUCCESS,
        payload: { data: injectNamesFromIds(data, satellites, groundStations), pagination: rest },
      });
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const createPassageBookerConfig = (data: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const satellites = getSatsFromStore(getState);
    const groundStations = getGroundStationsFromStore(getState);
    try {
      const { data: result } = await createBookerConfigs(data);
      dispatch({
        type: CREATE_BOOKER_CONFIG_SUCCESS,
        payload: injectNamesFromIds(result, satellites, groundStations)
      });
      dispatch(getPassageBookerConfigs());
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const updatePassageBookerConfigs = (id: any, data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data: result } = await updateBookerConfigs(id, data);
      dispatch({
        type: UPDATE_BOOKER_CONFIG_SUCCESS,
        payload: result
      });
      dispatch(getPassageBookerConfigs());
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};


export const bookingOnePassage = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data: result } = await bookingPassage(data);
      dispatch({
        type: CREATE_BOOK_REQUEST_SUCCESS,
        payload: result
      });
      dispatch(getPassageBookerConfigs());
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const deletePassageBookerConfig = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data: result } = await deleteBookerConfig(data);
      dispatch({
        type: DELETE_BOOKER_CONFIG_SUCCESS,
        payload: result
      });
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

function injectNamesFromIds(
  result: any,
  satellites: any[],
  groundStations: any[]
) {
  if (!result) return;

  return result
    .map((r: any) => {
      const idsToNamesMapped = Object.entries(
        r.groundStationsBySatellite
      ).reduce((acc: any, [satId, stations]: [any, any]) => {
        const satellite = satellites.find((s: any) => s.id === Number(satId));
        if (satellite) {
          acc[satellite.name] = stations.map((gs: any) =>
            findGSId(groundStations, gs)
          );
        } else {
          acc[satId] = stations.map((gs: any) => findGSId(groundStations, gs));
        }
        return acc;
      }, {});

      return {
        ...r,
        groundStationsBySatellite: idsToNamesMapped
      };
    })
    .sort((a: any, b: any) => b.id - a.id);
}

function getSatsFromStore(state: any) {
  return state().constellations.selected.satelliteInstances.map((s: any) => ({
    id: s.id,
    name: s.label
  }));
}

function getGroundStationsFromStore(state: any) {
  return state().groundStations.groundStations.map((gs: any) => ({
    id: gs.groundStationID,
    name: gs.groundStationName
  }));
}

function findGSId(groundStations: any[], groundStationId: number) {
  return groundStations.find((gs) => gs.id === groundStationId)?.name;
}
