import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { TypographyProps, CommonProps, BorderProps } from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Moment } from "moment";
import { isValidDate } from "app/shared/utils";
interface HTMLInput extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: any;
  value?: any;
  position?: string;
  paddingTop?: string;
  margin?: string;
  borderBottom?: string;
}

interface InputProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {
  dateFormat: string;
}

interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  position?: string | undefined;
  paddingTop?: string | undefined;
  margin?: string | undefined;
  borderBottom?: string;
  error: boolean;
}

const DateTimeStyled = styled.div<DivProps>`
  .rdt {
    outline: ${({ error }: any) => (error ? "1px solid red" : "unset")}
  }
  .rdtPicker{
    position: ${(props: any) => {
      return props.position || "relative";
    }} !important;
  }
  input {
    cursor: pointer;
    width: 125px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: ${(props) =>
      props.borderBottom ? props.borderBottom : "1px solid white"} ;
    padding-bottom: 5px;
    padding-top: ${(props: any) => {
      return props.paddingTop || "1px";
    }}
    font-size: 14px;
    color: ${themeGet("colors.palette.brand.0")};
    margin: ${(props: any) => {
      return props.margin || "0px";
    }}
  }
  input::placeholder {
    font-size: 14px;
    color: ${themeGet("colors.palette.brand.0")};
  }
  .rdtPicker {
    background-color: ${themeGet("colors.palette.blue.3")}
    border-color: ${themeGet("colors.palette.brand.0")}
  }
`;

const TIME_FORMAT = "HH:mm";

const DateTimePicker = (props: HTMLInput & InputProps) => {
  const {
    onChange,
    position,
    placeholder,
    paddingTop,
    margin,
    borderBottom,
    dateFormat
  } = props;
  const [close, setClose] = useState(false);
  const [value, setValue] = useState<Date | Moment | string>(
    props.value
      ? typeof props.value === "string"
        ? props.value
        : new Date(props.value)
      : ""
  );
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const dateTime = props.value
      ? typeof props.value === "string"
        ? props.value
        : new Date(props.value)
      : "";
    setValue(dateTime === "now" ? "" : dateTime);
  }, [props.value]);

  const dateValidationString = `${dateFormat} ${TIME_FORMAT}`;
  return (
    <DateTimeStyled
      {...props}
      position={position}
      paddingTop={paddingTop}
      margin={margin}
      borderBottom={borderBottom}
      error={error}
    >
      <Datetime
        timeFormat={TIME_FORMAT}
        inputProps={{
          placeholder: props.value === "now" ? props.value : placeholder
        }}
        utc={true}
        closeOnSelect={close}
        value={value}
        onFocus={() => setClose(false)}
        onChange={(newValue) => {
          const val =
            !newValue || typeof newValue === "string"
              ? newValue
              : newValue.toDate();
          if (isValidDate(val, dateValidationString) || !val) {
            setValue(val);
            setClose(true);
            onChange(val);
            return setError(false);
          }
        }}
        onBlur={(newValue) => {
          const val =
            !newValue || typeof newValue === "string"
              ? newValue
              : (newValue as Moment).toDate();
          if (isValidDate(val, dateValidationString) || !val) {
            setValue(val);
            setClose(true);
            onChange(val);
            return setError(false);
          }
          return setError(true);
        }}
        dateFormat={dateFormat}
      />
    </DateTimeStyled>
  );
};

DateTimePicker.displayName = "DateTimePicker";

DateTimePicker.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: DateTimePicker.displayName,
  dateFormat: "DD/MM/YYYY"
};

export default DateTimePicker;
